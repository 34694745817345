export default {
	"alias": "user",
	"customIcon": "icon-yonghubiao",
	"title": "用户",
	"pos": "top",
	"purview": [
		"room",
		"device_collect",
		"collect_record",
		"water_record",
		"water_bill",
		"charge_record",
		"refund_record",
		["room_card", 1],
		"trial_record",
	],
	"menus": [{
		"alias": "room",
		"customIcon": "icon-yonghubiao",
		"title": "用户列表",
		"pos": "left",
		"purview": "room",
		"link": "/user/room"
	}, {
		"alias": "warning",
		"customIcon": "icon-baojing",
		"title": "用户预警",
		"pos": "left",
		"purview": "room",
		"type": "submenu",
		"menus": [{
			"alias": "insufficient_balance",
			"title": "余额不足",
			"purview": "room",
			"badge": "room_insufficient_balance",
			"link": "/user/warning-insufficient_balance"
		}, {
			"alias": "unused_water",
			"title": "连续未用水",
			"purview": "room",
			"badge": "room_unused_water",
			"link": "/user/warning-unused_water"
		}]
	}, {
		"alias": "room_map",
		"customIcon": "icon-ditu",
		"title": "用户地图",
		"pos": "left",
		"purview": "room",
		"link": "/user/room_map"
	}, {
		"alias": "room_log",
		"customIcon": "icon-fuzhi",
		"title": "操作日志",
		"pos": "left",
		"purview": "room_log",
		"link": "/user/room_log"
	}, {
		"alias": "meter_collect",
		"customIcon": "icon-renwu",
		"title": "用户抄表",
		"pos": "left",
		"purview": "device_collect",
		"link": "/user/meter_collect"
	}, {
		"alias": "collect_record",
		"customIcon": "icon-wenjiandai",
		"title": "历史抄收",
		"pos": "left",
		"purview": "collect_record",
		"link": "/user/collect_record"
	}, {
		"alias": "water_record",
		"customIcon": "icon-wenjian1",
		"title": "详细用水",
		"pos": "left",
		"purview": "water_record",
		"link": "/user/water_record"
	}, {
		"alias": "water_bill",
		"customIcon": "icon-shujubiao",
		"title": "用水账单",
		"pos": "left",
		"purview": "water_bill",
		"link": "/user/water_bill"
	}, {
		"alias": "charge_record",
		"customIcon": "icon-qianbao",
		"title": "充值记录",
		"pos": "left",
		"purview": "charge_record",
		"link": "/user/charge_record"
	}, {
		"alias": "refund_record",
		"customIcon": "icon-zuochuan",
		"title": "退款记录",
		"pos": "left",
		"purview": "refund_record",
		"link": "/user/refund_record"
	}, {
		"alias": "room_card",
		"customIcon": "icon-shenfen",
		"title": "业主卡",
		"pos": "left",
		"purview": [
			["room_card", 1]
		],
		"link": "/user/room_card"
	}, {
		"alias": "trial_record",
		"customIcon": "icon-biaoqian1",
		"title": "试用记录",
		"pos": "left",
		"purview": "trial_record",
		"link": "/user/trial_record"
	}]
}