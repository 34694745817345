export default {
	"alias": "marketing",
	"customIcon": "icon-shujubiao",
	"title": "营销",
	"pos": "top",
	"purview": [
		["charge_option", 3],
		["charge_option_edit", 3],
		["trial_option", 3],
		["trial_option_edit", 3],
	],
	"menus": [{
		"alias": "charge",
		"customIcon": "icon-qianbao",
		"title": "充值套餐",
		"pos": "left",
		"purview": [
			["charge_option", 3]
		],
		"type": "submenu",
		"menus": [{
			"alias": "option",
			"title": "套餐设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge-option"
		}, {
			"alias": "area",
			"title": "区域设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge-area"
		}]
	}, {
		"alias": "charge1",
		"customIcon": "icon-shizhong",
		"title": "时效套餐",
		"pos": "left",
		"purview": [
			["charge_option", 3]
		],
		"type": "submenu",
		"menus": [{
			"alias": "option",
			"title": "套餐设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge1-option"
		}, {
			"alias": "area",
			"title": "区域设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge1-area"
		}]
	}, {
		"alias": "charge2",
		"customIcon": "icon-liwu",
		"title": "首充优惠",
		"pos": "left",
		"purview": [
			["charge_option", 3]
		],
		"type": "submenu",
		"menus": [{
			"alias": "option",
			"title": "套餐设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge2-option"
		}, {
			"alias": "area",
			"title": "区域设置",
			"purview": [
				["charge_option", 3]
			],
			"link": "/marketing/charge2-area"
		}]
	}, {
		"alias": "trial",
		"customIcon": "icon-rili",
		"title": "限时试用",
		"pos": "left",
		"purview": [
			["trial_option", 3]
		],
		"type": "submenu",
		"menus": [{
			"alias": "option",
			"title": "活动设置",
			"purview": [
				["trial_option", 3]
			],
			"link": "/marketing/trial-option"
		}, {
			"alias": "area",
			"title": "区域设置",
			"purview": [
				["trial_option", 3]
			],
			"link": "/marketing/trial-area"
		}]
	}]
}