export default {
	"alias": "partner",
	"customIcon": "icon-gongwenbao",
	"title": "合伙人",
	"pos": "top",
	"purview": [
		["partner_rate", [3]],
		["partner_bill", [3]],
		["partner_trade", [3]],
		"___is_partner___"
	],
	"menus": [{
		"alias": "manage",
		"customIcon": "icon-yonghubiao",
		"title": "合伙人管理",
		"pos": "left",
		"purview": [
			"partner_rate",
			"partner_bill",
			"partner_trade",
		],
		"type": "submenu",
		"menus": [{
			"alias": "partner_list",
			"title": "合伙人列表",
			"purview": "partner_rate",
			"link": "/partner/manage-partner_list"
		}, {
			"alias": "partner_rate",
			"title": "分成比例",
			"purview": "partner_rate",
			"link": "/partner/manage-partner_rate"
		}, {
			"alias": "trade_list2",
			"title": "线上分账明细",
			"purview": "partner_trade",
			"link": "/partner/manage-trade_list2"
		}, {
			"alias": "bill_list2",
			"title": "线上结算单",
			"purview": "partner_bill",
			"link": "/partner/manage-bill_list2"
		}, {
			"alias": "trade_list1",
			"title": "线下分账明细",
			"purview": "partner_trade",
			"link": "/partner/manage-trade_list1"
		}, {
			"alias": "bill_list1",
			"title": "线下结算单",
			"purview": "partner_bill",
			"link": "/partner/manage-bill_list1"
		}]
	}, {
		"alias": "ship",
		"customIcon": "icon-xitong",
		"title": "我是合伙人",
		"pos": "left",
		"purview": "___is_partner___",
		"type": "submenu",
		"menus": [{
			"alias": "index",
			"title": "资金账户",
			"purview": "___is_partner___",
			"link": "/partner/ship-index"
		}, {
			"alias": "trade_list2",
			"title": "线上分账明细",
			"purview": "___is_partner___",
			"link": "/partner/ship-trade_list2"
		}, {
			"alias": "bill_list2",
			"title": "线上结算申请",
			"purview": "___is_partner___",
			"link": "/partner/ship-bill_list2"
		}, {
			"alias": "withdraw_list",
			"title": "线上提现记录",
			"purview": "___is_partner___",
			"link": "/partner/ship-withdraw_list"
		}, {
			"alias": "trade_list1",
			"title": "线下分账明细",
			"purview": "___is_partner___",
			"link": "/partner/ship-trade_list1"
		}, {
			"alias": "bill_list1",
			"title": "线下结算申请",
			"purview": "___is_partner___",
			"link": "/partner/ship-bill_list1"
		}]
	}]
}