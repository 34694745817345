export default {
	"alias": "system",
	"customIcon": "icon-chilun",
	"title": "系统",
	"pos": "top",
	"purview": [
		["agent", 1],
		["agent_role", 2],
		["agent_user", 2],
		["operator", [1, 2]],
		["operator_role", 3],
		["operator_user", 3],
		["operator_area", 3],
		["operator_area_room", 3],
		["admin", 1],
		["admin_role", 1],
		["website", 1],
		["log", 1],
		["question", 1],
		"water_test_report"
	],
	"menus": [{
		"alias": "agent",
		"customIcon": "icon-renwu",
		"title": "代理商管理",
		"pos": "left",
		"purview": [
			["agent", 1]
		],
		"link": "/system/agent"
	}, {
		"alias": "operator",
		"customIcon": "icon-fuzhi",
		"title": "运营商管理",
		"pos": "left",
		"purview": [
			["operator", [1, 2]]
		],
		"link": "/system/operator"
	}, {
		"alias": "admin_role",
		"customIcon": "icon-shujubiao",
		"title": "角色配置",
		"pos": "left",
		"purview": [
			["admin_role", 1]
		],
		"link": "/system/admin_role"
	}, {
		"alias": "admin",
		"customIcon": "icon-yonghubiao",
		"title": "账号列表",
		"pos": "left",
		"purview": [
			["admin", 1]
		],
		"link": "/system/admin"
	}, {
		"alias": "agent_role",
		"customIcon": "icon-shujubiao",
		"title": "角色配置",
		"pos": "left",
		"purview": [
			["agent_role", 2]
		],
		"link": "/system/agent_role"
	}, {
		"alias": "agent_user",
		"customIcon": "icon-shenfen",
		"title": "账号列表",
		"pos": "left",
		"purview": [
			["agent_user", 2]
		],
		"link": "/system/agent_user"
	}, {
		"alias": "operator_role",
		"customIcon": "icon-shujubiao",
		"title": "角色配置",
		"pos": "left",
		"purview": [
			["operator_role", 3]
		],
		"link": "/system/operator_role"
	}, {
		"alias": "operator_user",
		"customIcon": "icon-shenfen",
		"title": "账号列表",
		"pos": "left",
		"purview": [
			["operator_user", 3]
		],
		"link": "/system/operator_user"
	}, {
		"alias": "operator_area",
		"customIcon": "icon-ditu",
		"title": "区域管理",
		"pos": "left",
		"purview": [
			["operator_area", 3]
		],
		"link": "/system/operator_area"
	}, {
		"alias": "operator_area_room",
		"customIcon": "icon-dianpu",
		"title": "机房管理",
		"pos": "left",
		"purview": [
			["operator_area_room", 3]
		],
		"link": "/system/operator_area_room"
	}, {
		"alias": "large_screen_setting",
		"customIcon": "icon-xianshiqi",
		"title": "智慧大屏设置",
		"pos": "left",
		"purview": [
			["operator_setting", 3]
		],
		"link": "/system/large_screen_setting"
	}, {
		"alias": "operator_setting",
		"customIcon": "icon-chilun",
		"title": "系统设置",
		"pos": "left",
		"purview": [
			["operator_setting", 3]
		],
		"link": "/system/operator_setting"
	}, {
		"alias": "website",
		"customIcon": "icon-shu",
		"title": "定制站点",
		"pos": "left",
		"purview": "website",
		"link": "/system/website"
	}, {
		"alias": "question",
		"customIcon": "icon-gongwenbao",
		"title": "常见问题",
		"pos": "left",
		"purview": "question",
		"link": "/system/question"
	}, {
		"alias": "water_test_report",
		"customIcon": "icon-wenjian",
		"title": "水质检测报告",
		"pos": "left",
		"purview": "water_test_report",
		"link": "/system/water_test_report"
	}, {
		"alias": "log",
		"customIcon": "icon-shu",
		"title": "日志",
		"pos": "left",
		"purview": "log",
		"type": "submenu",
		"menus": [{
			"alias": "sys",
			"title": "系统日志",
			"purview": "log",
			"link": "/system/log-sys"
		}, {
			"alias": "login",
			"title": "登录日志",
			"purview": "log",
			"link": "/system/log-login"
		}]
	}, {
		"alias": "wxmp",
		"customIcon": "icon-biaoqian1",
		"title": "微信公众号",
		"pos": "left",
		"purview": "___is_root___",
		"type": "submenu",
		"menus": [{
			"alias": "autoreply",
			"title": "被关注回复",
			"purview": "___is_root___",
			"link": "/system/wxmp-autoreply"
		}, {
			"alias": "menu",
			"title": "自定义菜单",
			"purview": "___is_root___",
			"link": "/system/wxmp-menu"
		}]
	}, {
		"alias": "setting",
		"customIcon": "icon-xitong",
		"title": "设置",
		"pos": "left",
		"purview": "___is_root___",
		"type": "submenu",
		"menus": [{
			"alias": "area",
			"title": "地区",
			"purview": "___is_root___",
			"link": "/system/setting-area"
		}, {
			"alias": "purview",
			"title": "权限",
			"purview": "___is_root___",
			"link": "/system/setting-purview"
		}, {
			"alias": "monitor",
			"title": "服务器",
			"purview": "___is_root___",
			"link": "/system/setting-monitor"
		}, {
			"alias": "nb_product",
			"title": "NB产品",
			"purview": "___is_root___",
			"link": "/system/setting-nb_product"
		}, {
			"alias": "ota",
			"title": "固件",
			"purview": "___is_root___",
			"link": "/system/setting-ota"
		}, {
			"alias": "report",
			"title": "报表",
			"purview": "___is_root___",
			"link": "/system/setting-report"
		}]
	}]
}