<style lang="less" scoped>
.menu {
	height: 64px;
	line-height: 64px;
	padding-left: 10px;
	overflow: hidden;
	li {
		color: @header-text-color!important;
	}
	a {
		line-height: 24px;
		color: @header-text-color!important;
		text-align: center;
	}
	li:hover,
	a:hover {
		border-bottom: none !important;
		color: @primary-color!important;
	}
	.ivu-menu-item-active,
	.ivu-menu-item-selected {
		border-bottom: none !important;
		color: @primary-color!important;
		border-bottom: 0px !important;
		box-shadow: 0px 0px 10px 0px rgba(41, 167, 237, 0.3);
		.ivu-icon {
			background: @bg-linear-color !important;
			background-clip: text !important;
			color: transparent !important;
		}
	}
	.ivu-menu-item {
		line-height: 24px !important;
		border-bottom: 0px !important;
		text-align: center;
	}
	.ivu-menu-item > i {
		margin-right: 0 !important;
	}
	&:after {
		background: none !important;
	}
	&-icon {
		margin-top: 10px;
	}
	&-title {
		font-size: 12px;
		height: 12px;
		line-height: 12px;
		margin-top: 7px;
	}
}
</style>

<template>
	<Menu ref="topMenu" mode="horizontal" :open-names="[]" :active-name="storeMenu.topActiveName" @on-select="handleClick" class="menu">
		<template v-for="(item, index) in menuList">
			<template v-if="item.pos == 'top' && storeBase.checkPurview(item.purview)">
				<template v-if="item.type == 'groupmenu'">
					<Submenu :name="item.alias">
						<template #title>
							<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="16" />
							<Icon v-else-if="item.icon" :type="item.icon" size="16" />
							{{ item.title }}
						</template>
						<template v-for="(groupItem, groupKey, groupIndex) in item.menus">
							<MenuGroup :title="groupKey">
								<template v-for="(item2, index2) in groupItem">
									<template v-if="storeBase.checkPurview(item2.purview)">
										<MenuItem :name="item.alias + '-' + item2.alias">
											<Icon v-if="item2.customIcon" :custom="'iconfont ' + item2.customIcon" size="16" />
											<Icon v-else-if="item2.icon" :type="item2.icon" size="16" />
											{{ item2.title }}
										</MenuItem>
									</template>
								</template>
							</MenuGroup>
						</template>
					</Submenu>
				</template>
				<template v-else-if="item.type == 'submenu'">
					<Submenu :name="item.alias">
						<template #title>
							<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="16" />
							<Icon v-else-if="item.icon" :type="item.icon" size="16" />
							{{ item.title }}
						</template>
						<template v-for="(item2, index2) in item.menus">
							<template v-if="storeBase.checkPurview(item2.purview)">
								<MenuItem :name="item.alias + '-' + item2.alias">
									<Icon v-if="item2.customIcon" :custom="'iconfont ' + item2.customIcon" size="16" />
									<Icon v-else-if="item2.icon" :type="item2.icon" size="16" />
									{{ item2.title }}
								</MenuItem>
							</template>
						</template>
					</Submenu>
				</template>
				<template v-else>
					<MenuItem :name="item.alias" :to="item.link">
						<div class="menu-icon">
							<Icon v-if="item.customIcon" :custom="'iconfont ' + item.customIcon" size="24" />
							<Icon v-else-if="item.icon" :type="item.icon" size="24" />
						</div>
						<div class="menu-title">{{ item.title }}</div>
					</MenuItem>
				</template>
			</template>
		</template>
	</Menu>
</template>

<script>
import { storeBase } from '@/store/base';
import { storeMenu } from '@/store/menu';
import { menuList, menuMap } from '@/common/menu';
export default {
	name: 'TopMenu',
	data() {
		return {
			storeBase: storeBase(),
			storeMenu: storeMenu(),
			menuList: menuList,
		};
	},
	computed: {},
	watch: {
		'storeMenu.handler'() {
			this.$nextTick(() => {
				this.$refs.topMenu.updateOpened();
				this.$refs.topMenu.updateActiveName();
			});
		},
	},
	methods: {
		handleClick(name) {
			this.storeMenu.topActiveName = this.storeMenu.topActiveName == name ? this.storeMenu.topKey : name;
			this.storeMenu.calcMode();
		},
	},
};
</script>
