import {
	httpGet,
	httpPost
} from '@/utils/http'

const config = {
	userType: {
		"k0": {
			"value": 0,
			"title": "系统",
			"color": "#ed4014"
		},
		"k1": {
			"value": 1,
			"title": "运维",
			"color": "#ff9900"
		},
		"k2": {
			"value": 2,
			"title": "代理商",
			"color": "#2d8cf0"
		},
		"k3": {
			"value": 3,
			"title": "运营商",
			"color": "#19be6b"
		},
		"k4": {
			"value": 4,
			"title": "会员",
			"color": "#17233d"
		}
	},
	yesNo: {
		"k0": {
			"value": 0,
			"title": "否",
			"color": "#ed4014"
		},
		"k1": {
			"value": 1,
			"title": "是",
			"color": "#17233d"
		},
	},
	valid: {
		"k0": {
			"value": 0,
			"title": "停用",
			"color": "#808695"
		},
		"k1": {
			"value": 1,
			"title": "启用",
			"color": "#19be6b"
		}
	},
	isDelete: {
		"k0": {
			"value": 0,
			"title": "正常",
			"boolTitle": "否",
			"color": "#19be6b"
		},
		"k1": {
			"value": 1,
			"title": "已删除",
			"boolTitle": "是",
			"color": "#ed4014"
		}
	},
	state: {
		"k0": {
			"value": 0,
			"title": "关闭",
			"color": "#808695"
		},
		"k1": {
			"value": 1,
			"title": "开启",
			"color": "#19be6b"
		}
	}
}

const fn = {}

const api = {
	/**
	 * 验证码
	 * @returns {*}
	 */
	captcha() {
		return httpGet('/captcha', {
			r: Math.random()
		})
	},
	/**
	 * 用户登录
	 * @param data.type							登录类型
	 * @param data.username						账号
	 * @param data.password						密码
	 * @param data.code							编码
	 * @param data.captcha						验证码
	 * @returns {*}
	 */
	login(data) {
		return httpPost('/login', data)
	},
	/**
	 * 锁定登录
	 * @param data
	 * @returns {*}
	 */
	lock(data) {
		return httpPost('/lock', data)
	},
	/**
	 * 退出登录
	 * @param data
	 * @returns {*}
	 */
	logout(data) {
		return httpPost('/logout', data)
	},
	/**
	 * 修改密码
	 * @param data.password						密码
	 * @param data.newPassword					新密码
	 * @returns {*}
	 */
	changepwd(data) {
		return httpPost('/changepwd', data)
	},
	/**
	 * 获取主题
	 * @returns {*}
	 */
	get_ui_data(data) {
		return httpGet('/get_ui_data', data)
	},
	/**
	 * 获取扩展数据
	 * @param data.mode						数据标识, 具体用法查看实现类
	 * @returns {*}
	 */
	get_extend_data(data) {
		return httpGet('/get_extend_data', data)
	}
}

export default {
	config,
	fn,
	api
}